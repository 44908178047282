<template>
    <v-dialog v-model="isOpen" width="30%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Ajout d'une pièce jointe
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-5">
                <ValidationObserver ref="observer">
                    <ValidationProvider v-slot="{ errors, failed }" name="Document" :rules="`document:${getConfig('messages.attachments.allowed_types', ['application/pdf']).join(',')}|size:${getConfig('messages.attachments.max_size', 20000000)}`">
                        <v-file-input v-model="file" label="Fichier (image ou document)" :accept="getConfig('messages.attachments.allowed_types', ['application/pdf'])" :error-messages="errors" :hide-details="!failed" clear-icon="far fa-times-circle" append-icon="fas fa-paperclip" prepend-icon="" chips small-chips show-size outlined dense />
                    </ValidationProvider>
                    Taille maximum : {{ getConfig('messages.attachments.max_size', 20000000) / 1000000 | round() }}Mo <br>
                    Type autorisés : {{ allowedDocumentTypes }}
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" :disabled="file === null" color="primary" small> Ajouter </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import mime from 'mime-types';
export default {
    name: 'CreateMessageAttachmentDialog',

    data: () => ({
        isOpen: false,
        file: null
    }),

    methods: {
        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            this.isOpen = false;
            this.$emit('added', this.file);
        }
    },

    computed: {
        allowedDocumentTypes() {
            const mimeTypes = this.getConfig('messages.attachments.allowed_types', ['application/pdf']);
            return mimeTypes.map((t) => mime.extension(t)).join(', ');
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.file = null;
            }
        }
    }
};
</script>
