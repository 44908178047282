<template>
    <v-dialog v-model="isOpen" width="30%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Modification de message
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text>
                <v-textarea v-model="newMessage" rows="1" placeholder="Répondre" background-color="grey lighten-4" rounded hide-details auto-grow />
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Modifier </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'UpdateMessageDialog',

    props: {
        message: { type: Object, required: true },
        index: { type: Number, required: true }
    },

    data: () => ({
        isOpen: false,
        newMessage: ''
    }),

    methods: {
        async submit() {
            try {
                this.setLoading(true);

                const body = {
                    content: this.newMessage
                };
                const { success, err } = await this.repos.messaging.updateMessage(this.message.groupId, this.message.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('updated', { index: this.index, content: this.newMessage });
                    this.$notify({
                        title: 'Succès',
                        text: 'Votre message à bien été modifié',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la suppression du message',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.newMessage = this.message.content;
            }
        }
    }
};
</script>
