var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Ajout d'une pièce jointe ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-5"},[_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"name":"Document","rules":("document:" + (_vm.getConfig('messages.attachments.allowed_types', ['application/pdf']).join(',')) + "|size:" + (_vm.getConfig('messages.attachments.max_size', 20000000)))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-file-input',{attrs:{"label":"Fichier (image ou document)","accept":_vm.getConfig('messages.attachments.allowed_types', ['application/pdf']),"error-messages":errors,"hide-details":!failed,"clear-icon":"far fa-times-circle","append-icon":"fas fa-paperclip","prepend-icon":"","chips":"","small-chips":"","show-size":"","outlined":"","dense":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})]}}])}),_vm._v(" Taille maximum : "+_vm._s(_vm._f("round")(_vm.getConfig('messages.attachments.max_size', 20000000) / 1000000))+"Mo "),_c('br'),_vm._v(" Type autorisés : "+_vm._s(_vm.allowedDocumentTypes)+" ")],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.file === null,"color":"primary","small":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Ajouter ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }