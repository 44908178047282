<template>
    <v-menu right offset-x>
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-list class="py-0" dense flat>
            <template v-for="attachment of message.attachments">
                <v-list-item :key="attachment.id">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ attachment.sourceFileName }}
                        </v-list-item-title>
                    </v-list-item-content>

                    <v-divider class="ml-2 my-4" vertical></v-divider>

                    <v-list-item-action class="d-flex flex-row">
                        <v-btn :href="getAttachmentUrl(attachment)" target="_blank" icon small>
                            <v-icon small> fas fa-external-link-alt </v-icon>
                        </v-btn>

                        <v-btn :href="getAttachmentUrl(attachment, true)" target="_blank" icon small>
                            <v-icon small> fas fa-download </v-icon>
                        </v-btn>

                        <v-btn color="red" icon small>
                            <v-menu right offset-x nudge-right="20px" nudge-top="10px">
                                <template v-slot:activator="{ on }">
                                    <v-icon small v-on="on"> fas fa-times </v-icon>
                                </template>

                                <v-card class="pa-2">
                                    Supprimer ?
                                    <v-btn @click="deleteAttachment(attachment)" class="mx-2" color="app-blue" dark small> Oui </v-btn>
                                    <v-btn color="red" dark small> Non </v-btn>
                                </v-card>
                            </v-menu>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="attachment.id + '-divider'" />
            </template>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'MessageAssetsMenu',

    props: {
        message: { type: Object, required: true }
    },

    methods: {
        getAttachmentUrl(attachment, download = false) {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/messaging/attachments/${attachment.id}${download ? '?download=1' : ''}`;
        },

        async deleteAttachment(attachment, i) {
            try {
                this.setLoading(true);

                const { success, err } = await this.repos.messaging.deleteMessageAttachment(attachment.id);
                if (success) {
                    this.content = '';
                    this.$notify({
                        title: 'Succès',
                        text: 'La pièce jointe a bien été supprimée',
                        type: 'success'
                    });
                    this.$emit('deleted', i);
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la suppression de la pièce jointe',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>
