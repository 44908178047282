<template>
    <div>

        <v-textarea v-model="content" class="message-input" rows="1" :placeholder="placeholder" background-color="white" color="app-blue" rounded outlined hide-details auto-grow dense>
            <template v-slot:prepend>
                <v-menu top offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn icon small v-on="on">
                            <v-icon small> fas fa-plus </v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <CreateMessageAttachmentDialog @added="addAttachment($event)">
                            <template v-slot:activator="{ on }">
                                <v-list-item v-on="on">
                                    <v-list-item-content>
                                        Ajouter une pièce jointe
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </CreateMessageAttachmentDialog>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:append-outer>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="createNewMessage()" icon v-on="on">
                            <v-icon class="mb-3"> fas fa-paper-plane </v-icon>
                        </v-btn>
                    </template>
                    Envoyer
                </v-tooltip>
            </template>
        </v-textarea>

        <template>
            <v-list class="py-0" v-if="attachments.length > 0" dense>
                <v-list-item v-for="(attachment, i) of attachments" :key="i">
                    <v-list-item-avatar>
                        <v-icon color="app-blue" small> fas fa-paperclip </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ attachment.name }}
                        </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn @click="removeAttachment(i)" icon small>
                            <v-icon color="red" small> fas fa-times </v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </template>
    </div>
</template>

<script>
import CreateMessageAttachmentDialog from './CreateMessageAttachmentDialog.vue';

export default {
    name: 'CreateMessageInput',

    props: {
        conversationId: { type: Number, required: true },
        firstMessage: { type: Boolean, default: true },
        newRecipients: { type: Array }
    },

    components: {
        CreateMessageAttachmentDialog
    },

    data: () => ({
        content: '',
        attachments: []
    }),

    methods: {
        async createNewMessage() {
            if (!this.content.trim() || this.isLoading) {
                return;
            }

            try {
                this.isLoading = true;

                const body = {
                    content: this.content,
                    attachments: this.attachments
                };

                if (this.firstMessage && Array.isArray(this.newRecipients) && this.newRecipients.length > 0) {
                    body.newRecipients = this.newRecipients;
                }

                const { success, message, err } = await this.repos.messaging.createMessage(this.conversationId, body);
                if (success) {
                    this.content = '';
                    this.attachments = [];
                    this.$notify({
                        title: 'Succès',
                        text: 'Votre message à bien été envoyé',
                        type: 'success'
                    });
                    this.$emit('created', message);
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de l\'envoi du message',
                    type: 'error'
                });
            } finally {
                this.isLoading = false;
            }
        },

        addAttachment(file) {
            this.attachments.push(file);
        },

        removeAttachment(i) {
            this.attachments.splice(i, 1);
        }
    },

    computed: {
        placeholder() {
            return this.firstMessage ? 'Envoyer un message' : 'Répondre';
        }
    }
};
</script>
