<template>
    <v-menu v-model="isOpen" width="30%" max-height="400px" :close-on-content-click="false" offset-x>
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-text-field ref="searchInput" v-model="search" placeholder="Nom, email" solo flat hide-details />

            <v-divider />

            <v-list-item-group v-model="selectedRecipients">
                <v-list-item v-for="recipient of recipients" :value="recipient" :key="recipient.email">
                    <v-list-item-avatar class="blue white--text text-uppercase" size="32">
                        {{ recipient.name.slice(0, 1) }}
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title class="body-2">
                            {{ recipient.name }} {{ recipient.firstname }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            {{ recipient.email }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                            <v-chip v-for="program of recipient.programs" :key="program.title" class="mr-1" small label>
                                {{ program.title }} -
                                <template v-for="product of program.products">
                                    <span :key="product.reference" class="ml-1"> {{ product.reference }}</span>
                                </template>
                            </v-chip>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="contacts.length === 0">
                    <v-list-item-content>
                        <v-list-item-title class="body-2">
                            Aucun résultat
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>

            <v-overlay v-show="isLoading">
                <v-progress-circular indeterminate size="64" />
            </v-overlay>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: 'CreateMessageGroupMenuBuyer',

    props: {
        activeConversations: { type: Array, default: () => ([]) }
    },

    data: () => ({
        isOpen: false,
        search: '',
        selectedRecipients: [],
        contacts: [],
        searchTimeout: null,
        isLoading: false
    }),

    methods: {
        async fetchContacts(query = {}) {
            try {
                this.isLoading = true;
                query.limit = 20;
                if (this.search) {
                    query.quickSearch = this.search;
                }
                const { contacts } = await this.repos.contacts.getContacts(query);
                this.contacts = contacts;
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        }
    },

    computed: {
        recipients() {
            return this.contacts.filter((c) => {
                return !this.activeConversations.some((conv) => conv.users.some((u) => u.id == c.email && u.type === 'buyer'));
            });
        }
    },

    watch: {
        selectedRecipients() {
            if (this.selectedRecipients) {
                const recipients = [this.selectedRecipients].map((r) => ({
                    id: r.email,
                    name: r.name,
                    firstname: r.firstname,
                    type: 'buyer'
                }));
                this.$emit('created', { recipients });
                this.isOpen = false;
            }
        },

        search() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.fetchContacts();
            }, 500);
        }
    },

    created() {
        this.fetchContacts();
    }
};
</script>
