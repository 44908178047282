var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.message.last && _vm.index !== 0)?_c('v-row',{staticClass:"my-4 grey--text text--darken-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-divider',{staticClass:"mr-4"}),_vm._v(" Anciens messages "),_c('v-divider',{staticClass:"ml-4"})],1):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:{ 'grey lighten-5': hover }},[(!_vm.isPreviousMessageFromSameUser(_vm.message, _vm.index))?_c('v-list-item-avatar',{staticClass:"white--text text-uppercase mb-auto",attrs:{"color":_vm.getColorByName(_vm.message.user.name),"size":"32"}},[_vm._v(" "+_vm._s(_vm.message.user.name ? _vm.message.user.name.slice(0, 1) : '')+" ")]):_c('v-list-item-avatar',{attrs:{"size":"32"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"grey--text text-caption"},on),[_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.message.created,false))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm._f("toDatetime")(_vm.message.created))+" ")])],1),_c('v-list-item-content',{staticClass:"py-0"},[(!_vm.isPreviousMessageFromSameUser(_vm.message, _vm.index))?_c('v-list-item-title',{staticClass:"body-2"},[(_vm.message.user.name || _vm.message.user.firstname)?[_vm._v(" "+_vm._s(_vm.message.user.name)+" "+_vm._s(_vm.message.user.firstname)+" ")]:[_c('i',[_vm._v(" <Compte Inactif> ")])],_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"grey--text text-caption"},on),[_vm._v(" "+_vm._s(_vm.getDate(_vm.message.created))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm._f("toDatetime")(_vm.message.created))+" ")])],2):_vm._e(),_c('v-list-item-subtitle',{staticClass:"user-content"},[_vm._v(" "+_vm._s(_vm.message.content)+" ")]),(_vm.message.attachments.length > 0)?_c('v-list-item-action-text',[_c('MessageAssetsMenu',{attrs:{"message":_vm.message},on:{"deleted":function($event){return _vm.$emit('attachmentDeleted', _vm.message, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"flex-grow-0",attrs:{"icon":"","x-small":""}},on),[_c('v-icon',[_vm._v(" fas fa-paperclip ")])],1)]}}],null,true)})],1):_vm._e()],1),(_vm.message.isMe)?_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-ellipsis-h ")])],1)]}}],null,true)},[_c('v-list',[_c('UpdateMessageDialog',{attrs:{"message":_vm.message,"index":_vm.index},on:{"updated":function($event){return _vm.$emit('updated', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" Modifier ")])]}}],null,true)}),_c('DeleteMessageDialog',{attrs:{"message":_vm.message,"index":_vm.index},on:{"deleted":function($event){return _vm.$emit('deleted', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" Supprimer ")])]}}],null,true)})],1)],1)],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }